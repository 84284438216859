import LogoImg from 'assets/images/_logos/codaheadLogo.svg';
import LogoShort from 'assets/images/_logos/codaheadWrappedLogo.svg';
import UeLogoImg from 'assets/images/_logos/ue_logo.png';
import { THeaderTabType } from 'components/_layout/Header/Header';
import { BurgerButton, HeaderMenuItem, IconLine, StyledLink, UeLogo } from 'components/_layout/Header/Header.styled';
import ArrowContact from 'assets/images/_infoGraphics/arrowContact.svg';
import { TabCompany } from 'components/_layout/Header/TabCompany';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import { useTheme } from 'layout/ThemeContext';
import React from 'react';
import { TAKE_THIS_STEP_ID } from 'shared/links';
import { ROUTE_CASE_STUDIES, ROUTE_CONTACT, ROUTE_HOME } from 'shared/paths';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const LogoWithMenu = ({
  activeTab,
  handleTabSelect,
}: {
  activeTab: THeaderTabType;
  handleTabSelect: (arg: THeaderTabType) => void;
}) => {
  const { headerMenuIsOpen, setHeaderMenuIsOpen } = useTheme();
  const isMd = useBreakpoint('md');
  const isLg = useBreakpoint('lg');

  const scrollDown = () => {
    scrollTo({
      behavior: 'smooth',
      top: document.body.scrollHeight,
    });
  };

  const handleScrollToContact = () => {
    const browserLocation = typeof window !== 'undefined' && window.location.href;

    if (browserLocation) {
      const domain = browserLocation.substring(browserLocation.lastIndexOf('/'));
      if (domain === ROUTE_HOME) {
        const element = document.getElementById(TAKE_THIS_STEP_ID);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        navigate(ROUTE_CONTACT);
      }
    }
  };

  return (
    <Common.Div w="100%" flex="row" justifyContent="space-between" alignItems="center">
      <Common.Div flex="row" alignItems="center" h="54px">
        <StyledLink to={ROUTE_HOME}>
          <img alt="logo" src={isMd && headerMenuIsOpen ? LogoShort : LogoImg} />
        </StyledLink>
        {isMd && headerMenuIsOpen && (
          <Common.Div onClick={() => scrollDown()} mr={4} ml={4}>
            <UeLogo src={UeLogoImg} />
          </Common.Div>
        )}
      </Common.Div>
      {!isLg && headerMenuIsOpen && (
        <>
          <HeaderMenuItem onClick={() => handleTabSelect('OFFER')} isActive={activeTab === 'OFFER'}>
            Offer
          </HeaderMenuItem>
          <HeaderMenuItem onClick={() => handleTabSelect('INDUSTRIES')} isActive={activeTab === 'INDUSTRIES'}>
            Industries
          </HeaderMenuItem>
          <HeaderMenuItem
            onClick={() => {
              setHeaderMenuIsOpen(false);
              navigate(ROUTE_CASE_STUDIES);
            }}
            isActive={activeTab === 'CASE_STUDIES'}
          >
            Case studies
          </HeaderMenuItem>
          <div>
            <HeaderMenuItem onClick={() => handleTabSelect('COMPANY')} isActive={activeTab === 'COMPANY'}>
              Company
            </HeaderMenuItem>
            <TabCompany isActive={activeTab === 'COMPANY' && !isLg} />
          </div>
        </>
      )}
      <Common.Div flex="row" alignItems={'center'}>
        {!isMd && (
          <Common.Div mr={4} flex="row" alignItems="center">
            <UeLogo src={UeLogoImg} onClick={() => scrollDown()} />
            <Common.Svg onClick={handleScrollToContact} src={ArrowContact} ml={4} />
          </Common.Div>
        )}
        <BurgerButton
          aria-label="burger-button"
          isOpen={headerMenuIsOpen}
          onClick={() => setHeaderMenuIsOpen(!headerMenuIsOpen)}
        >
          <IconLine />
          <IconLine />
          <IconLine />
        </BurgerButton>
      </Common.Div>
    </Common.Div>
  );
};
