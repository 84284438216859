import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import { isMarketingCookiesDisabled } from 'utils/cookies';

interface IHeadProps {
  title?: string;
  description?: string;
  thumbnail?: string;
  article?: boolean;
  pathname: string;
}

export default ({ title, description, thumbnail, pathname, article }: IHeadProps) => (
  <StaticQuery
    query={QueryHead}
    render={({
      site: {
        siteMetadata: { site, defaultTitle, titleTemplate, defaultDescription, language, siteUrl },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: thumbnail || `${siteUrl}/assets/thumbnail.png`,
        url: `${siteUrl}${pathname}`,
      };

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <html lang={language ? language : 'en'} />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="application-name" content={site} />
            <link rel="canonical" href={seo.url} />
            <meta property="og:url" content={seo.url} />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />
            {article && <meta property="og:type" content="article" />}
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-title" content={site} />
            <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              as="style"
              href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap"
              rel="stylesheet"
            />
            <script>
              {`  var grantButton = document.getElementById('acceptButton');
                                grantButton.addEventListener("click", function() {
                                localStorage.setItem("consentGranted", "true");
                                function gtag() { dataLayer.push(arguments); }

                                gtag('consent', 'update', {
                                ad_user_data: 'granted',
                                ad_personalization: 'granted',
                                ad_storage: 'granted',
                                analytics_storage: 'granted'
                                });
                            });

                            var gtagScript = document.createElement('script');
                            gtagScript.async = true;
                            gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=AW-780446271';

                            var firstScript = document.getElementsByTagName('script')[0];
                            firstScript.parentNode.insertBefore(gtagScript,firstScript);`}
            </script>
          </Helmet>
          {!isMarketingCookiesDisabled() && (
            <Helmet>
              <script async src="https://www.googletagmanager.com/gtag/js?id=AW-780446271" />
              <script>
                {`window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'AW-780446271');`}
              </script>
            </Helmet>
          )}
        </>
      );
    }}
  />
);

const QueryHead = graphql`
  query QueryHead {
    site {
      siteMetadata {
        site
        siteUrl
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        language
      }
    }
  }
`;
