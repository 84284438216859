exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ai-tsx": () => import("./../../../src/pages/ai.tsx" /* webpackChunkName: "component---src-pages-ai-tsx" */),
  "component---src-pages-blog-confirm-error-tsx": () => import("./../../../src/pages/blog-confirm-error.tsx" /* webpackChunkName: "component---src-pages-blog-confirm-error-tsx" */),
  "component---src-pages-blog-confirm-success-tsx": () => import("./../../../src/pages/blog-confirm-success.tsx" /* webpackChunkName: "component---src-pages-blog-confirm-success-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-deep-tech-tsx": () => import("./../../../src/pages/deep-tech.tsx" /* webpackChunkName: "component---src-pages-deep-tech-tsx" */),
  "component---src-pages-european-funds-tsx": () => import("./../../../src/pages/european-funds.tsx" /* webpackChunkName: "component---src-pages-european-funds-tsx" */),
  "component---src-pages-gpt-tsx": () => import("./../../../src/pages/gpt.tsx" /* webpackChunkName: "component---src-pages-gpt-tsx" */),
  "component---src-pages-hardware-tsx": () => import("./../../../src/pages/hardware.tsx" /* webpackChunkName: "component---src-pages-hardware-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-tsx": () => import("./../../../src/pages/industries.tsx" /* webpackChunkName: "component---src-pages-industries-tsx" */),
  "component---src-pages-job-offer-tsx": () => import("./../../../src/pages/job-offer.tsx" /* webpackChunkName: "component---src-pages-job-offer-tsx" */),
  "component---src-pages-join-us-tsx": () => import("./../../../src/pages/join-us.tsx" /* webpackChunkName: "component---src-pages-join-us-tsx" */),
  "component---src-pages-magazine-tsx": () => import("./../../../src/pages/magazine.tsx" /* webpackChunkName: "component---src-pages-magazine-tsx" */),
  "component---src-pages-other-inquiries-tsx": () => import("./../../../src/pages/other-inquiries.tsx" /* webpackChunkName: "component---src-pages-other-inquiries-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-software-tsx": () => import("./../../../src/pages/software.tsx" /* webpackChunkName: "component---src-pages-software-tsx" */),
  "component---src-pages-staff-augmentation-tsx": () => import("./../../../src/pages/staff-augmentation.tsx" /* webpackChunkName: "component---src-pages-staff-augmentation-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-sections-blog-post-page-post-page-tsx": () => import("./../../../src/sections/Blog/PostPage/PostPage.tsx" /* webpackChunkName: "component---src-sections-blog-post-page-post-page-tsx" */),
  "component---src-sections-our-projects-project-section-tsx": () => import("./../../../src/sections/OurProjects/ProjectSection.tsx" /* webpackChunkName: "component---src-sections-our-projects-project-section-tsx" */)
}

