import { ITypographyProps, spacingProps } from 'components/_universal/commonStyles';
import { blueButtonAnimation } from 'shared/animations/animations';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

const commonTypographyStyles = (p: ITypographyProps) =>
  css`
    text-align: ${p.align ?? 'left'};
    margin: 0;
    padding: 0;
    ${p.onClick && 'cursor: pointer'};
    font-weight: ${p.bold ? 700 : 400};
    ${p.fontStyle && `font-style: ${p.fontStyle}`};
    ${p.decoration &&
    `text-decoration: ${p.decoration};
        text-decoration-color: ${p.theme.colors.hoverBlue}`};
    color: ${() => {
      return p.secondary ? p.theme.colors.textSecondary : p.theme.colors.text;
    }};
    ${p.textWrap && `text-wrap: ${p.textWrap}`};
    ${p.maxW && `max-width: ${p.maxW}`};
    ${p.alignSelf && `align-self: ${p.alignSelf}`};
  `;

export const SectionWrapper = styled.div(
  ({ theme: { containerWidth } }) =>
    css`
      display: flex;
      margin: 0 auto;
      max-width: ${containerWidth.default};
      width: ${containerWidth.xs};

      ${mediaQueries.md} {
        height: 100%;
        max-width: 100%;
      }
    `
);

export const H1 = styled.h1(
  ({ theme: { fontSizes, fontWeight, fontFamily } }) =>
    css`
      ${commonTypographyStyles};
      ${spacingProps};
      font-family: ${fontFamily.heading};
      font-size: ${fontSizes.h1};
      font-weight: ${fontWeight.regular};
      line-height: 80px;

      ${mediaQueries.xs} {
        font-size: ${fontSizes.f42};
        line-height: 46px;
      }
    `
);

export const H2 = styled.h2(
  ({ theme: { fontSizes, fontWeight, fontFamily } }) =>
    css`
      font-family: ${fontFamily.heading};
      display: flex;
      justify-content: space-between;
      gap: 20px;
      ${commonTypographyStyles};
      ${spacingProps};
      font-size: ${fontSizes.h2};
      font-weight: ${fontWeight.light};

      ${mediaQueries.xs} {
        font-size: 2em;
      }
    `
);

export const H3 = styled.h3(
  ({ theme: { fontWeight, fontFamily } }) =>
    css`
      font-size: 34px;
      font-weight: ${fontWeight.light};
      font-family: ${fontFamily.heading};

      ${commonTypographyStyles};
      ${spacingProps};

      ${mediaQueries.xs} {
        font-size: 2em;
      }
    `
);

export const B0 = styled.p(
  ({ theme: { fontSizes, fontWeight } }) =>
    css`
      font-size: ${fontSizes.b0};
      font-weight: ${fontWeight.regular};
      line-height: 35px;
      ${commonTypographyStyles};
      ${spacingProps};
    `
);

export const B1 = styled.p(
  ({ theme: { fontSizes, fontWeight } }) =>
    css`
      line-height: 35px;
      ${commonTypographyStyles};
      ${spacingProps};
      font-size: ${fontSizes.b1};
      font-weight: ${fontWeight.light};
    `
);

export const B2 = styled.p(
  ({ theme: { fontSizes, fontWeight } }) =>
    css`
      font-size: ${fontSizes.b2};
      line-height: 25px;
      font-weight: ${fontWeight.regular};
      ${commonTypographyStyles};
      ${spacingProps};
    `
);

export const B3 = styled.p(
  ({ theme: { fontSizes, fontWeight } }) =>
    css`
      font-size: ${fontSizes.b3};
      font-weight: ${fontWeight.light};
      line-height: 25px;
      ${commonTypographyStyles};
      ${spacingProps};
    `
);

export const B4 = styled.p(
  ({ theme: { fontSizes, fontWeight } }) =>
    css`
      font-size: ${fontSizes.b4};
      font-weight: ${fontWeight.light};
      line-height: normal;
      letter-spacing: 0.33px;
      ${commonTypographyStyles};
      ${spacingProps};
    `
);

export const H4 = styled.h4(
  ({ theme: { fontSizes, fontWeight } }) =>
    css`
      font-size: ${fontSizes.h4};
      font-weight: ${fontWeight.light};
      line-height: 54px;
      ${commonTypographyStyles};
      ${spacingProps};
    `
);

export const SectionText = styled.p(
  ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) =>
    css`
      color: ${colors.white};
      font-family: ${fontFamily.secondary};
      font-size: ${fontSizes.f14};
      font-weight: ${fontWeight.regular};
      line-height: 25px;
      ${commonTypographyStyles};
      ${spacingProps};

      ${mediaQueries.sm} {
        font-size: ${fontSizes.f14};
      }
    `
);

export const Text = styled.p(
  ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) =>
    css`
      color: ${colors.mainBlue};
      font-family: ${fontFamily.secondary};
      font-size: ${fontSizes.f14};
      font-weight: ${fontWeight.regular};
      line-height: 22px;

      ${mediaQueries.sm} {
        font-size: ${fontSizes.f12};
      }
    `
);

export const sharedButtonStyles = css(
  ({ theme: { fontSizes, fontWeight } }) =>
    css`
      align-items: center;
      border: none;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      font-size: ${fontSizes.f13};
      font-weight: ${fontWeight.regular};
      justify-content: center;
      line-height: 13px;
      padding: 17px 43px;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      width: fit-content;
      height: fit-content;
    `
);

export const PrimaryButton = styled.button(
  ({ theme: { colors } }) =>
    css`
      ${sharedButtonStyles};
      background: ${colors.mainBlue};
      color: ${colors.white};
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      width: 160px;

      // &:hover {
      //     animation: ${blueButtonAnimation} 0.5s ease-in-out forwards;
      // }

      &[disabled] {
        background: ${colors.mainBlue}50;
        cursor: not-allowed;
        transition: all 0.75s ease-in-out;
      }
    `
);

export const StyledExternalLink = styled.a(
  ({ theme: { colors } }) =>
    css`
      p {
        align-items: center;
        display: flex;
        text-decoration: none;
        color: white;
        cursor: pointer;

        &:visited {
          text-decoration: none;
        }

        &:hover {
          color: ${colors.hoverBlue};
        }
      }
    `
);
